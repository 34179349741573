header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  padding: 1rem 0.5rem;
  background-color: #FAEED3;
  border-bottom: 0.5px solid #4C102D;
  z-index: 1;
}

.header-logo {
  height: 2rem;
  width: 7rem
}

.location {
  margin-left: 1rem;
  padding: 0;
  position: relative;
}

.globe-icon {
  font-size: 1.15rem;
  color: #4C102D;
  cursor: pointer;
}

.location-text {
  color: #4C102D;
  width: 6rem;
  font-size: 0.5rem;
  position: absolute;
  top: 1.75rem;
  left: -1.8rem;
}

.desktop-nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
}

.desktop-nav-links li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.desktop-nav-links li:hover {
  color: #4C102D;
}

.link-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  margin-right: 0.5rem;
}

.active-link li {
  color: #4C102D;
}

.non-active-link li {
  color: #927256;
}

.menu-closebtn,
.menu-openbtn {
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  color: #4C102D;
}

.mobile-menu {
  width: 100%;
  position: absolute;
  top: 5.75rem;
  left: 0;
  background-color: #FAEED3;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.mobile-navlink {
  color: #4C102D;
  display: flex;
  margin-bottom: 1.75rem;
  font-size: 1.25rem;
  text-decoration: none;
}


.mobile-navlink li {
  text-decoration: none;
  margin: 1rem;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 300px) {

  .header-logo {
    height: 2.75rem;
    width: 9.75rem
  }

  .menu-closebtn,
  .menu-openbtn {
    font-size: 2.25rem;
  }

  .globe-icon {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 948px) {
  
  header {
    height: 6rem;
    padding: 0.85rem 4rem;
  }

  .header-logo {
    height: 3.5rem;
    width: 12.5rem
  }

  .menu-closebtn, .menu-openbtn {
    display: none;
  }

  .location {
    margin-left: 2rem;
  }

  .location-text {
    font-size: 0.8rem;
    width: 15rem;
    left: 1.75rem;
    top: 0.25rem;
  }

  .link-icon {
    margin-right: 0;
  }

}