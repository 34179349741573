.motion-div1, .motion-div2, .motion-div3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 100%;
  height: 100vh;
  width: 100vw;
}

.motion-div1 {
  z-index: 4;
  background-color: #FFF8E4;
}

.motion-div2 {
  z-index: 3;
  background-color: #FFF;
}

.motion-div3 {
  z-index: 2;
  background-color: #4C102D;
}