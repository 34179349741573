.contact-section {
  background-color: #fff;
  margin: 0 auto;
}

.contact-article {
  color: #4C102D;
}

.contact-text {
  padding: 1.5rem;
  margin: 1.5rem 0 0;
  border-bottom: 1px solid #eae9e9;
}

.contact-text h2,
.contact-text h4 {
  margin-bottom: 1rem;
  font-weight: 550;
}

.contact-info {
  padding: 1.5rem;
  margin: 0;
}

.contact-info h2 {
  margin-bottom: 1.5rem;
}

.contact-info h5 {
  margin: 1rem 0;
}

.contact {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  height: 2rem;
}

.contact a {
  color: #4C102D;
}

.contact a:hover {
  color: #927256;
}

.react-icon {
  font-size: 1.1rem;
  display: flex;
  margin: 0 0.5rem 0 0;
}

@media screen and (min-width: 948px) {

  .contact-article {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .contact-text,
  .contact-info {
    padding: 3rem;
    margin: 0;
  }

  .contact-text {
    border-bottom: none;
  }

  .react-icon {
    font-size: 1.5rem;
  }

}

@media screen and (min-width: 1200px) {
  .contact-section {
    margin-bottom: 7rem;
  }
  
}
