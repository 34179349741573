.error-section {
  display: block;
  height: 100vh;
  min-width: 350px;
  background-color: #fff;
  margin: 0;
  border: 1px solid #fff;
  color: #4C102D;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: fadein 1s;
}

.error-article {
  height: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.error-article h1 {
  width: 30rem;
  text-align: center;
  margin: 0 auto;
}

.error-link {
  margin: 0 auto;
  color: #4C102D;
  font-size: 1.1rem;
  text-decoration: underline;
  text-align: center;
}

.error-link:hover {
  color: #927256;
  cursor: pointer;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}