.services-section {
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

.services-article {
  height: fit-content;
  margin: 0 0 1rem 0;
  padding: 2rem 1rem;
  border-bottom: 2px solid #eae9e9;
  color: #4C102D;
}

.services-article:last-of-type {
  border-bottom: none;
  padding: 2rem 1rem 0;
}

.service-icon {
  height: 2rem;
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.services-article h4 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 948px) {

  .services-section {
    padding: 2rem 5rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .services-article {
    height: 35rem;
    margin: 2rem;
    padding: 1.5rem;
    border: 1px solid #eae9e9;
    border-radius: 5%;
    box-shadow: rgba(76, 16, 45, 0.16) 0px 3px 6px, rgba(76, 16, 45, 0.23) 0px 3px 6px;
    overflow: hidden;
  }

  .service-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 0 1rem 0;
  }

  .services-article h4 {
    text-align: left;
    justify-content: flex-start;
    font-size: 1.4rem;
  }

}
