@import 'bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Numans&display=swap');

body {
  margin: 0;
  font-family: 'Numans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  /* max-width: 1600px; */
  margin: 0 auto;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}
